<template>
  <div>
    <el-dialog :title="title" :visible.sync="open" width="700px" @close="commitOnClose">
      <el-form ref="saveOrEditForm" :model="saveData" style="width:500px" :rules="rules" label-width="160px">
        <template v-if="!batch">
          <el-form-item label="SN号" prop="sn">
            {{selectRow.sn}}
          </el-form-item>
          <el-form-item label="活动名称" prop="activityName">
              {{selectRow.activityName}}
          </el-form-item>
        </template>
        <template v-else>
          <el-form-item label="连续SN号" prop="beginSn">
            <el-input v-model="saveData.beginSn" style="width:160px"></el-input> - <el-input v-model="saveData.endSn" style="width:160px"></el-input>
          </el-form-item>
          <el-form-item label="非连续SN号" prop="sn">
              <FileUpload @change="getFile"></FileUpload>
          </el-form-item>
        </template>
        <el-form-item label="活动名称" prop="policyNo">
            <el-select v-model="saveData.activityNo" size="small" placeholder="请选择活动名称" style="width: 340px">
                <el-option v-for="(item,index) in activetyList" :label="item.activityName" :value="item.activityNo" :key="index"></el-option>
            </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submit" v-if="!batch">确定</el-button>
        <el-button type="primary" @click="submitBatch" v-else>确定</el-button>
        <el-button type="primary" @click="cancel">取消</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { TerminalApi } from '@/api'
import FileUpload from '@/components/FileUpload'
export default {
  name: 'CustomForm',
  props: {
    dicts: {
      type: Object,
      default: function () {
        return {}
      },
    },
    isAdd: {
      type: Boolean,
      default: true,
    },
    selectRow: {
      type: Object,
      default: function () {
        return {}
      },
    },
    onFresh: {
      type: Boolean,
      default: false,
    },
    batch: {
      type: Boolean,
      default: true,
    },
  },
  components:{FileUpload},
  data() {
    return {
      title: '活动变更',
      saveData: {},
      activetyList: [],
      open: false,
      merchantNo: '',
      merchantName: '',
      rules: {
        type: [
          { required: true, message: '请选择业务类型', trigger: 'change' },
        ],
        userRole: [
          { required: true, message: '请选择配置对象', trigger: 'change' },
        ],
        merchantNo: [
          { required: true, message: '请输入商户编号', trigger: 'blur' },
        ],
      },
    }
  },
  watch: {
    onFresh: function (value) {
      this.open = value
      if (value) {
        this.resetData()
      }
    },
  },
  created() {
    TerminalApi.queryInfo.getActivityInfo()
      .then(res=>{
          if(res.success){
              this.activetyList = res.data
          }
      })
  },
  methods: {
    async resetData() {
      this.saveData = this.selectRow
    },
    async submitBatch() {
      if(!this.saveData.snFileUrl&&(!this.saveData.beginSn || !this.saveData.endSn)){
          this.Message.error('请输入连续SN号或者上传SN号文件')
          return
      }
      if (!this.saveData.activityNo) {
        this.Message.error('请选择需要更换的活动')
        return 
      }
      let result = await TerminalApi.terminalInfo.updateActivityBatch(this.saveData)
      if (result.success) {
        this.Message.success('变更成功')
        this.commitOnClose()
      }
    },
    async submit() {
      if (!this.saveData.activityNo) {
        this.Message.error('请选择需要更换的活动')
        return 
      }
      let result = await TerminalApi.terminalInfo.updateActivity(this.saveData.sn,this.saveData.activityNo)
      if (result.success) {
        this.Message.success('变更成功')
        this.commitOnClose()
      }
    },
    cancel() {
      this.saveData = {}
      this.commitOnClose()
    },
    async getMerchantName() {
      let res = await TerminalApi.terminalInfo.queryMerchantName(
        this.saveData.merchantNo
      )
      if (res.success) {
        this.merchantName = res.data.merchantName
      } else {
        this.merchantName = ''
        this.Message.error(res.message)
      }
    },
    commitOnClose() {
      this.merchantName = ''
      this.$emit('on-close')
    },
    getFile(file){
        this.saveData.snFileUrl = file
    }
  },
}
</script>

<style scoped>
</style>
